@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-marketplace-background text-gray-800;
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.product-card {
  @apply bg-white rounded-lg shadow-product-card p-4 transition-all duration-300 hover:scale-105;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
