@keyframes snowfall {
  0% {
    transform: translate3d(0, -100%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(0, 1000px, 0) rotate(360deg);
  }
}

.snowflake {
  position: fixed;
  top: -10px;
  color: white;
  text-shadow: 0 0 2px rgba(0,0,0,0.1);
  animation: snowfall linear infinite;
  z-index: 1;
  pointer-events: none;
  user-select: none;
}

.christmas-title {
  color: #c41e3a;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
}

.christmas-card {
  position: relative;
  transition: transform 0.3s ease;
}

.christmas-card:hover {
  transform: translateY(-5px);
}

.christmas-card::before {
  content: '🎄';
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 24px;
  transform: rotate(15deg);
}

.christmas-search {
  border: 2px solid #c41e3a;
  transition: all 0.3s ease;
}

.christmas-search:focus {
  border-color: #8b0000;
  box-shadow: 0 0 0 3px rgba(196, 30, 58, 0.2);
}

.empty-state-christmas {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
  color: #c41e3a;
}

.empty-state-christmas::before {
  content: '🎅';
  font-size: 48px;
}

.text-shadow-lg {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
